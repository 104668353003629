import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RadioInput from './RadioInput';

const CityFormField = () => {
  const {
    allWpCity: { nodes: cities },
  } = useStaticQuery(graphql`
    {
      allWpCity(sort: { fields: title }) {
        nodes {
          title
        }
      }
    }
  `);
  const [{ value }, { error, touched }, { setValue }] = useField('nearestCity');

  return (
    <>
      <label
        htmlFor="nearestCity"
        className={`u-label mb-6 ${touched && error && 'u-label--error'}`}
      >
        Nearest City to Event Location
      </label>
      <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-3">
        {cities.map(({ title: city }) => (
          <div key={city}>
            <RadioInput
              label={city}
              selected={value === city}
              error={touched && !!error}
              onClick={() => setValue(city)}
            />
          </div>
        ))}
        <div>
          <RadioInput
            label="Other"
            selected={value === 'Other'}
            error={touched && !!error}
            onClick={() => setValue('Other')}
          />
        </div>
      </div>
      <AnimatePresence>
        {touched && error && (
          <motion.div
            className="text-red-accessibility pt-0.5 mt-2"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            {error}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CityFormField;
