import axios from 'axios';
import enUS from 'date-fns/locale/en-US';
import { Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import startCase from 'lodash/startCase';
import moment from 'moment';
import React, { useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { object, string } from 'yup';
import MoreThanADonutShopImage from '../../images/more-than-a-donut-shop.png';
import DonationsForm from '../molecules/DonationsForm';

registerLocale('en-US', enUS);

const validationSchema = object().shape({
  name: string().required('Please enter your name / company'),
  phoneNumber: string().required('Please enter your phone number'),
  email: string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
  business: string().required('Please enter your business / organization name'),
  nearestCity: string().required('Please select your nearest city'),
  nearestCityOther: string().when('nearestCity', {
    is: 'Other',
    then: string().required('Please enter your nearest city'),
  }),
  howWouldYouLikeTheSaltyToSupportYourCause: string().required(
    'Please tell us how you would like us to support your cause'
  ),
});

const DonationsFormSection = () => {
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);

  const initialValues = {
    name: '',
    phoneNumber: '',
    email: '',
    business: '',
    dateOfEvent: '',
    nearestCity: '',
    nearestCityOther: '',
    howWouldYouLikeTheSaltyToSupportYourCause: '',
    additionalInfo: '',
  };

  const handleSubmit = async (values) => {
    const formattedData = Object.entries(values).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [startCase(key)]:
          key === 'dateOfEvent' && value
            ? moment(value).format('MM/DD/YY')
            : value,
      }),
      {}
    );

    setError(null);
    setSuccess(false);

    try {
      await axios.post(
        'https://mailer.saltydonut.com/api/donations',
        { data: formattedData },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setSuccess(true);
    } catch (e) {
      setError('Form submission has failed.');
    }
  };

  return (
    <section className="relative py-32">
      <img
        src={MoreThanADonutShopImage}
        alt="More than a donut shop"
        className="absolute top-0 z-20 h-auto -ml-14 w-28 lg:w-40 -mt-14 lg:-mt-20 lg:-ml-20 left-1/2 animate-spin-slow"
      />
      <div className="container">
        <div className="max-w-[608px] mx-auto">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(props) => <DonationsForm {...props} />}
          </Formik>
          <AnimatePresence>
            {error && (
              <motion.div
                className="text-red-accessibility pt-0.5 mt-6"
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
              >
                {error}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {success && (
              <motion.div
                key="success"
                className="flex items-center mt-6 space-x-6 text-2xl font-bold uppercase font-heading text-navy-blue"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div>
                  Thank you for submitting your request. We will be in contact
                  soon if we are able to support your cause.
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default DonationsFormSection;
