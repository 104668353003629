import { Form, FormikProps } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import Button from '../atoms/Button';
import CityFormField from '../atoms/CityFormField';
import DateInput from '../atoms/DateInput';
import FormInput from '../atoms/FormInput';

const DonationsForm = (props: FormikProps<any>) => (
  <Form className="space-y-6">
    <div>
      <FormInput name="name" label="Name / Company" />
    </div>
    <div>
      <FormInput name="phoneNumber" type="tel" label="Phone Number" />
    </div>
    <div>
      <FormInput name="email" type="email" label="Your Email Address" />
    </div>
    <div>
      <FormInput name="business" label="Business / Organization Name" />
    </div>
    <div>
      <DateInput
        name="dateOfEvent"
        label="What's is the date of the event? (if applicable)"
        placeholder="MM/DD/YY"
      />
    </div>
    <div>
      <CityFormField />
    </div>
    <div>
      <AnimatePresence>
        {props.values.nearestCity === 'Other' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FormInput name="nearestCityOther" placeholder="Other" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
    <div>
      <FormInput
        name="howWouldYouLikeTheSaltyToSupportYourCause"
        label="How would you like The Salty to support your cause?"
        isTextArea
      />
    </div>
    <div>
      <FormInput
        name="additionalInfo"
        label="Additional info or message"
        isTextArea
      />
    </div>
    <div className="pt-2">
      <Button type="submit" variant="default" disabled={props.isSubmitting}>
        {props.isSubmitting ? 'Submitting...' : 'Send'}
      </Button>
    </div>
  </Form>
);

export default DonationsForm;
