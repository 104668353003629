import { AnimatePresence } from 'framer-motion';
import React from 'react';

interface RadioInputProps {
  label: string;
  selected?: boolean;
  error?: boolean;
  onClick: () => void;
}

const RadioInput = ({ label, selected, error, onClick }: RadioInputProps) => (
  <button
    type="button"
    className="flex items-center w-full space-x-2 text-left text-navy-blue group focus:outline-none"
    onClick={onClick}
  >
    <div
      className={`flex-shrink-0 w-6 h-6 ${
        error ? 'border-2 border-red-accessibility' : 'border border-[#b4b4b4]'
      } rounded-full transition-all flex items-center justify-center ${
        !selected &&
        'group-focus-visible:outline-none group-focus-visible:ring-[#4375BF] group-focus-visible:ring-offset-2 group-focus-visible:ring-1 group-focus-visible::border-black group-hover:border-black '
      }`}
    >
      <AnimatePresence>
        {selected && <div className="w-3.5 h-3.5 bg-black rounded-full" />}
      </AnimatePresence>
    </div>
    <span>{label}</span>
  </button>
);

export default RadioInput;
