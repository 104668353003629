import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import DonationsFormSection from '../components/organisms/DonationsFormSection';
import DonationsHero from '../components/organisms/DonationsHero';
import { PromoPopup } from '../components/organisms/PromoPopup';

const Donations = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <DonationsHero {...page.donations.donationsHero} />
        <DonationsFormSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query DonationsPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      donations {
        donationsHero {
          heading
          body
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Donations;
